<template>
    <div>
        <!-- 通道管理》通道效果回传管理》通道效果回传配置管理 增加/修改 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
                <el-form :model="form_data" label-width="150px" style="margin-bottom: 12px;">
                    <el-form-item label="通道">
                        <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%" :disabled="this.prop_change_type == 'upd'"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="签名" >
                        <el-input v-model="form_data.signword" placeholder="请输入签名" size="mini" type="text" maxlength="200"
                        :disabled="this.prop_change_type == 'upd'"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="价格（分/条）" >
                        <el-input v-model="form_data.price" placeholder="请填写价格（分/条）" size="mini"
                        type="text" maxlength="6" @input="inputPriceEnter"></el-input>
                    </el-form-item>

                    <el-form-item label="回传比例" >
                        <el-input v-model="form_data.rate" placeholder="请填写回传比例(范围：1-100，支持1位小数点)" size="mini"
                        type="text" maxlength="4" @input="inputRateEnter">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../../api/api';

export default {
    props:{
        prop_change_i:0,//添加状态 组件 弹出框是否可见
        prop_change_type:"",//类型：添加或修改
        prop_change_item:{},//状态对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见
                if(this.prop_change_type === "add"){
                    this.cur_title = "通道效果回传配置添加";
                }else {
                    this.cur_title = "通道效果回传配置修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();
                    }, 1);   
                }
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            form_data:{
                id:"",//主键id
                channel_id:"",//通道id
                signword:"",//签名
                price:"",//价格（分/条）
                rate:"",//回传比例(范围：1-100，支持1位小数点)
            },

            channelDataList:[],//通道列表数据-请求接口获取
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);  
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
         //查询
        getData(){
            this.form_data.id = this.prop_change_item.id;//主键id
            this.form_data.channel_id = this.prop_change_item.channel_id;//通道id
            this.form_data.signword = this.prop_change_item.signword;//签名
            this.form_data.price = this.prop_change_item.price;//价格(分/条)
            this.form_data.rate = this.prop_change_item.rate;//回传比例(范围：1-100，支持1位小数点)
        },
        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },
        inputPriceEnter(){
            this.form_data.price = this.form_data.price.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.price = this.form_data.price.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两位小数点
        },
        inputRateEnter(){
            this.form_data.rate = this.form_data.rate.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.rate = this.form_data.rate.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3"); // 只能输入一位小数点
        },
        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.channel_id)){
                this.$message.error("请选择通道");
                return;
            }
            if(API.isEmtry(this.form_data.signword)){
                this.$message.error("请填写签名");
                return;
            }
            if(API.isEmtry(this.form_data.price)){
                this.$message.error("请填写价格");
                return;
            }
            if(API.isEmtry(this.form_data.rate)){
                this.$message.error("请填写回传比例");
                return;
            }
            var submit_obj = {};
            if(this.prop_change_type === "upd"){//修改
                submit_obj.param = "update";
                submit_obj.id = this.form_data.id;
            }else{//增加
                submit_obj.param = "add";
                submit_obj.channel_id = this.form_data.channel_id;
                submit_obj.signword = this.form_data.signword;
            }
            submit_obj.price = this.form_data.price;
            submit_obj.rate = this.form_data.rate;
            
            API.ChannelBackhaulServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },
    }
};
</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>