<template>
    <!-- 通道效果回传配置管理 -->
    <div id="channel_backhaul_list_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">通道效果回传配置管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <!-- 查询条件 -->
            <el-row id="query_condition_id_202205200952" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="68px">
                    <el-form-item label="通道">
                        <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%" placeholder="默认查询全部"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="channel_id" label="通道id" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="channel_name" label="通道" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="signword" label="签名" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="price" label="价格（分/条）" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="rate" label="回传比例" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="操作" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="update(scope.row)">修改</el-button>
                            <el-button type="text" size="mini" style="color:#f56c6c" @click="del(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>

        <!-- 企信通平台自定义失败状态配置-添加状态 -->
        <ChannelBackhaulAddUpd :prop_change_i="prop_channel_backhaul_i" :prop_change_type="prop_channel_backhaul_type"
        :prop_change_item='prop_channel_backhaul_item'
        @result="submit_cha_xun"></ChannelBackhaulAddUpd>
    </div>
</template>
<script>
import API from '../../../api/api';
import ChannelBackhaulAddUpd from '../../channel/channel_backhaul/channel_backhaul_add_upd.vue';//组件 自定义失败状态增加

export default {

    components: {
        ChannelBackhaulAddUpd  
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                channel_id:"",//查询条件-通道id 空字符表示全部
            },

            channelDataList:[],//通道列表数据-请求接口获取

            tableData:[],//表格数据
            query_condition_height:40,//查询条件的高度

            //通道效果回传配置管理 组件 弹出框是否可见
            prop_channel_backhaul_i: 0,//添加状态 组件 弹出框是否可见
            prop_channel_backhaul_type: 'add',//类型：添加或修改
            prop_channel_backhaul_item: {},//状态对象
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();
        //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                    var obj = {id: "", channel_name: "默认查询全部" };
                    this.channelDataList.unshift(obj);
                }
            });
        },
        //查询
        submit_cha_xun(){
            //请求接口
            API.ChannelBackhaulServlet({
                param: "list",
                channel_id:this.form_data.channel_id,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id_202205200952');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //添加
        add() {
            this.prop_channel_backhaul_i++;
            this.prop_channel_backhaul_type = "add";
        },
        //删除
        del(id) {
            this.$confirm('此操作将永久删除该回传比例, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //请求接口
                API.ChannelBackhaulServlet({
                    param: "del",
                    id:id,
                }).then((res) => {
                    if (res.recode === 0) {
                        this.$message({showClose: true,message: '恭喜你，删除成功', type: 'success'});
                        //重新加载数据
                        this.submit_cha_xun();
                    }
                });
            }).catch(() => {//已取消删除
            });
        },
        //修改
        update(item) {
            this.prop_channel_backhaul_i++;
            this.prop_channel_backhaul_type = "upd";
            this.prop_channel_backhaul_item = item;
        },
        //返回
        goBack() {
            API.router_to("/channel_backhaul_index");
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>